import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 4×6 to a 6RM`}</p>
    <p>{`then,`}</p>
    <p>{`100-Double Unders`}</p>
    <p>{`1-Squat Snatch (135/95)(RX+ 155/105)`}</p>
    <p>{`75-Double Unders`}</p>
    <p>{`2-Squat Snatch`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`3-Squat Snatch`}</p>
    <p>{`25-Double Unders`}</p>
    <p>{`4-Squat Snatch`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`The Ville will reopen Monday, June 1st at 6:00am! Check out the
Schedule page for an updated list of class times. We hope to get back to
our normal class schedule asap and will keep you updated on any
changes.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      